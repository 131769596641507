<template>
  <div class="company-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="real-estate/page"
      @selection-change="handleSelectionChange"
    >
      <el-button
        slot="btn"
        size="mini"
        style="height: 26px;padding: 0 10px;margin-left: 10px;"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>

      <el-button
        v-if="$auth('楼盘新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open(0)">
        {{$l('common.add','新增')}}
      </el-button>
      <el-button
        v-if="$auth('楼盘合并')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.mergePage.open(selectList)">
        {{$l("common.add", "合并")}}
      </el-button>

      <el-button
        v-if="$auth('楼盘地理位置批量识别')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="handleAddress">
        地理位置批量识别
      </el-button>
      <el-button
        v-if="$auth('楼盘导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>



      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼盘名称">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('project.name','维保班组')" prop="teamName">
            <el-input v-model.trim="filter.teamName" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <!--      <div slot="tree" :style="{width:width+'px'}" class="treeBox">-->
      <!--        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>-->
      <!--      </div>-->
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column label="楼盘名称" align="center" prop="name">
      </el-table-column>
      <el-table-column prop="districtName" label="省市区" align="center"></el-table-column>
      <el-table-column prop="customerCompanyName" label="客户公司" align="center"></el-table-column>
      <el-table-column prop="teamName" :label="$l('project.name','维保班组')" align="center">
        <template slot-scope="scope">
          <span v-for="(item,index) in scope.row.teams" :key="index">{{item.name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorQuantity" label="在保台量" align="center"></el-table-column>
      <el-table-column prop="signRadius" label="签到半径" align="center"></el-table-column>
      <el-table-column prop="longitude" label="经度" align="center"></el-table-column>
      <el-table-column prop="latitude" label="纬度" align="center"></el-table-column>
      <el-table-column prop="serviceInterval" label="保养周期" align="center"></el-table-column>
      <el-table-column prop="latitude" label="是否发送到96333 " align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isTransfer">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="isSkipMaintained" label="是否一键跳过保养 " align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isSkipMaintained">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="latitude" label="状态 " align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status">启用</el-tag>
          <el-tag v-else type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="latitude" label="保养状态 " align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isMaintain">启用</el-tag>
          <el-tag v-else type="danger">禁用</el-tag>
        </template>
      </el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button v-if="$auth('楼盘编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l('common.edit','编辑')}}</el-button>
          <el-button v-if="$auth('楼盘编辑')" type="primary" @click="$refs.reportList.open(scope.row.id)">{{$l('common.edit','报告')}}</el-button>
          <el-button v-if="$auth('楼盘状态')" :loading="changeStatusLoading" :type="scope.row.status?'danger':'primary'" @click="changeStatus(scope.row)">{{scope.row.status?$l('common.edit','禁用'):$l('common.edit','启用')}}</el-button>
          <el-button v-if="$auth('楼盘状态')" :loading="changeStatusLoading" :type="scope.row.isMaintain?'danger':'primary'" @click="changeMaintainStatus(scope.row)">{{scope.row.isMaintain?$l('common.edit','保养禁用'):$l('common.edit','保养启用')}}</el-button>
          <el-button v-if="$auth('楼盘删除')" type="danger" @click="deleteRow(scope.row)">{{$l('common.delete','删除')}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <merge-page ref="mergePage" @save-success="getList(-1)"></merge-page>
    <report-list ref="reportList" @save-success="getList(-1)"></report-list>

    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/realEstate.xlsx"
        action="real-estate/import"
        name="楼盘导入"
        download-name="楼盘模板.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>
<script>
  import EditPage from "./Edit.vue";
  import MergePage from "./Merge";
  import {export2Excel, getWidth} from "@/util";
  import {downloadFiles} from "@/util";
  import moment from "moment";
  import ReportList from "@/views/elevatorUsingRealEstate/ReportList";
  import loginUtil from "@/util/loginUtil";

  export default {
    components: {EditPage,MergePage,ReportList},
    data() {
      return {
        loading:false,
        visible:false,
        changeStatusLoading:false,
        filter: {
          name: "",
          teamName: "",
          creditCode: "",
          companyId: loginUtil.getCompanyCode(),
          // usingCompanyName:"",
        },
        selectList:[],
        data:[],
        width:200,
      };
    },

    mounted() {
      this.getList(1);
      this.getTreeList();
    },
    methods: {
      exportExcelData() {
        const title={
          name:"楼盘",
          districtName:"省市区",
          customerCompanyName:"客户公司",
          // teamName:"维保班组",
          elevatorQuantity:"在保台量",
        };
        this.$http.get("real-estate/page",{...this.filter,pageSize:3000000}).then(res=>{

          export2Excel(title,res.records,`电梯档案-常规信息-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
      },
      handleSelectionChange(val) {
        this.selectList = val;
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("real-estate", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-and-with-customer-company");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
      // handleNodeClick(e) {
      //   if (e.other==="customerCompany") {
      //     this.filter.usingCompanyName=e.label;
      //     this.getList(-1);
      //   }
      //
      // },
      async downFile() {
        this.loading=true;
        downloadFiles("excelTemplate/realEstate.xlsx","楼盘模板.xlsx",()=> {
          this.loading=false;
        });
      },
      handleAddress() {
        this.$http.post("real-estate/address/batch-location-by-tencent");
        this.getList(-1);
      },
      changeStatus(row) {
        this.changeStatusLoading =true;
        this.$http.post(`/real-estate/status/${row.id}`,{
          status:!row.status,
        }).then(()=>{
          this.changeStatusLoading=false;
          this.$message.success(row.name + this.$l("common.deleteSuccessTip", "修改成功"));

          this.getList(-1);

        }).catch(()=>{
          this.changeStatusLoading= false;
        });
      },
      changeMaintainStatus(row) {
        this.isMaintainLoading =true;
        this.$http.post(`/real-estate/isMaintain/${row.id}`,{
          isMaintain:!row.isMaintain,
        }).then(()=>{
          this.isMaintainLoading=false;
          this.$message.success(row.name + this.$l("common.deleteSuccessTip", "修改成功"));

          this.getList(-1);

        }).catch(()=>{
          this.isMaintainLoading= false;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1300px);
  max-height: 750px;
  overflow-y: auto;
}
</style>
